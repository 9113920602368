import React from 'react'; 
import { Button } from 'semantic-ui-react';
import { SearchBar } from 'dyl-components';
import '../index.scss';

const Toolbar = ({ search, onSearchSubmit, cancelFunction, onChangeSearch }) => {
    return (
        <div style={{display: 'flex'}}>
            <div style={{flex: 1}}>
                <SearchBar
                    isSearching={false}
                    search={search}
                    searchFcn={onSearchSubmit}
                    cancelFcn={cancelFunction}
                    placeholder='Search by label'
                    addClassNames='PhoneAudio__search'
                    onChange={onChangeSearch}
                />
            </div>
            <div>
                <Button style={{marginRight: 10}} className='PhoneAudioToolbar__button' onClick={() => {}}>
                    + New
                </Button>
            </div>
        </div>

    )
}

export default Toolbar;
